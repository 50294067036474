import "./App.css";
import phone from "./assets/phone.svg";
import location from "./assets/map.svg";
import email from "./assets/email.svg";
import instagram from "./assets/instagram.svg";
import zdklogo from "./assets/logoblack.svg";

function App() {
  const SocialMediaData = [
    {
      name: "Instagram",
      link: "https://www.instagram.com/zdkmotorsport",
      label: "@zdkmotorsport",
      icon: instagram,
    },
    {
      name: "Email",
      link: "mailto:geral@zdkmotorsport.com",
      label: "geral@zdkmotorsport.com",
      icon: email,
    },
    {
      name: "Phone",
      link: "tel:+351910273803",
      label: "+351 910 273 803",
      icon: phone,
    },
  ];
  //responsive
  //git

  return (
    <div className="zdkMain">
      <header className="zdkHeader">
        <img src={zdklogo} alt="zdk logo" />
        <h1>Marque a sua próxima revisão</h1>
      </header>

      <div className="Footer">
        <div className="Location">
          <img height={20} width={20} src={location} alt="morada" />
          <p>
            <span>Rua Professor António Marques Nº74</span>
            <span>4425-364 Maia</span>
            <span>Portugal</span>
          </p>
          <div id="map"></div>
        </div>

        <div className="SocialMediaWrapper">
          {SocialMediaData.map((item, index) => {
            return (
              <div className="SocialMedia">
                <a
                  key={index}
                  href={item.link || ""}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img height={20} width={20} src={item.icon} alt={item.name} />
                  <span>{item.label}</span>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default App;
